<template>
  <div class="flex xs12">
    <local-table
      class="table-hover table-striped"
      :editable="edit"
      :columns="membersFields"
      :data="membersList"
      :loading="loading"
      :top-options="[]"
      @delete-item="tryDelete"
      @download-item="prepareDownload"
    >
      <template v-slot:top v-if="edit">
        <div class="flex xs12 md2">
          <va-button
            flat
            small
            color="primary"
            icon="fa fa-plus"
            @click.prevent="tryAdd"
          >
            {{ $t("tables.actions.add") }}
          </va-button>
        </div>
        <div class="flex xs12 md2">
          <va-button
            flat
            small
            color="primary"
            icon="fa fa-save"
            @click.prevent="submit"
          >
            {{ $t("tables.actions.save") }}
          </va-button>
        </div>
      </template>
      <template v-slot:row-actions="{ props }">
        <va-popover
          :message="$t('tables.actions.remove_from_list')"
          placement="left"
        >
          <va-button
            flat
            small
            color="danger"
            icon="fa fa-times"
            @click.prevent="tryDelete(props.rowData)"
          />
        </va-popover>
      </template>
    </local-table>
    <va-modal
      v-if="edit"
      size="large"
      v-model="editModal"
      cancel-class="none"
      :title="$t('teams.modal.title')"
      :ok-text="$t('modal.confirm')"
      :cancel-text="$t('modal.cancel')"
      :ok-disabled="haveErrors || isLoading"
      @ok="addUser"
      @cancel="member = ''"
    >
      <div class="row">
        <error-notification :show="error.existent">
          <span>{{ $t("teams.modal.error.existent") }}</span>
        </error-notification>
        <error-notification :show="error.leader">
          <span>{{ $t("teams.modal.error.leader") }}</span>
        </error-notification>
        <error-notification :show="error.invalid">
          <span>{{ $t("teams.modal.error.invalid") }}</span>
        </error-notification>
        <div class="flex xs12">
          <va-select
            v-model="member"
            text-by="label"
            :options="members"
            :loading="isLoading"
            :no-options-text="$t('layout.empty')"
            :disabled="loading"
            @update-search="findAllUsers"
            searchable
          />
        </div>
      </div>
    </va-modal>
  </div>
</template>
<script>
const LocalTable = () =>
  import(
    /* webpackPrefetch: true */ "@/components/extras/DataTables/LocalTable"
  );

export default {
  name: "members-table",
  components: {
    LocalTable,
  },
  props: {
    edit: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    team: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  data() {
    return {
      currentQuery: "",
      members: [],
      membersList: [],
      member: "",
      editModal: false,
      error: {
        existent: false,
        leader: false,
        invalid: false,
      },
      isLoading: false,
    };
  },
  computed: {
    haveErrors() {
      return this.error.existent || this.error.leader || this.error.invalid;
    },
    membersFields() {
      return [
        {
          name: "name",
          title: this.$t("tables.headings.name"),
        },
        {
          name: "email",
          title: this.$t("tables.headings.email"),
        },
        {
          name: "role",
          title: this.$t("tables.headings.role"),
        },
        {
          name: "__slot:actions",
          visible: this.membersList.length > 0 && this.edit,
          dataClass: "text-right",
          width: "20%",
        },
      ];
    },
  },
  watch: {
    team(val) {
      if (val.users.length > 0) {
        const users = this.fixData(val.users);
        this.membersList = users.slice(0);
      }
      this.findAllUsers();
    },
    member(val) {
      this.checkResult(val);
    },
  },
  created() {
    this.findAllUsers();
  },
  methods: {
    fixData(data) {
      for (const u of data) {
        const role =
          u.id === this.team.leader_id
            ? "teams.tabs.history.leader"
            : "teams.tabs.history.member";
        u.role = this.$t(role);
        const label = `${u.name} - ${u.email}`;
        u.label = label;
      }

      return data;
    },
    filterData(data) {
      const d = data.filter(
        (m) => this.membersList.findIndex((u) => u.id === m.id) === -1
      );
      return this.fixData(d);
    },
    async findAllUsers(search = "") {
      this.currentQuery = search;
      this.isLoading = true;
      const queries = ["sort=name", "direction=asc", "group=6"];
      const queries2 = ["sort=name", "direction=asc", "search=true"];

      if (this.team.country_id) {
        queries.push("country=" + this.team.country_id);
        queries2.push("country=" + this.team.country_id);
        queries2.push(`district=${this.team.district_id}`);
      }
      if (search.length > 0) {
        queries.push("q=" + search);
        queries2.push("q=" + search);
      }

      const query = queries.join("&");
      const route = `/users?${query}`;
      const query2 = queries2.join("&");
      const route2 = `/users?${query2}`;
      let response = false;
      let response2 = false;
      try {
        response = await this.$http.get(route);
        response2 = await this.$http.get(route2);
      } catch (e) {
        // console.log('Error', e)
        this.isLoading = false;
        return;
      }

      var arr = [...response.data.data /*, ...response2.data.data */];
      for (var i = 0; i < response2.data.data.length; i++) {
        var value = response2.data.data[i];
        const index = arr.findIndex((object) => object.id === value.id);

        if (index === -1) {
          arr.push(value);
        }
      }
      if (this.currentQuery !== search) return;
      this.members = this.filterData(arr) || [];
      this.isLoading = false;
    },
    checkResult(user) {
      this.error = {
        existent: false,
        leader: false,
        invalid: false,
      };

      const existent = this.membersList.find((m) => m.id === user.id);
      if (existent !== undefined) {
        this.error.existent = true;
      }

      this.member = user;
    },
    tryAdd() {
      this.editModal = true;
    },
    addUser() {
      if (this.haveErrors) {
        return;
      }

      this.member.team_id = this.team.id;
      this.membersList.push(this.member);
      this.membersList = this.fixData(this.membersList);
      this.members = this.filterData(this.members);
      this.member = "";
    },
    async tryDelete(item) {
      const result = await this.$swal({
        icon: "warning",
        text: this.$t("notifications.confirm.delete"),
        showCancelButton: true,
        confirmButtonText: this.$t("layout.buttons.confirm"),
        cancelButtonText: this.$t("layout.buttons.cancel"),
      });
      if (result.value !== true) return;

      if (item.id === this.team.leader_id) {
        this.showToast(this.$t("notifications.confirm.nodelete"), {
          icon: "fa-times",
          position: "top-right",
        });
        return;
      }
      this.membersList = this.membersList.filter((m) => m.id !== item.id);
      this.members.push(item);
      this.members = this.members.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      });
    },
    async prepareDownload(format) {
      const prepareRoute = "users/download?team=" + this.team.id;
      const downloadRoute = "users/download/";

      return this.downloadFile({ format: format }, prepareRoute, downloadRoute);
    },
    async downloadFile(filters, prepareRoute, downloadRoute) {
      let fileName = "";
      const prepare = {
        icon: "info",
        title: this.$t("notifications.download.prepare"),
        confirmButtonText: this.$t("notifications.download.button"),
        text: this.$t("notifications.download.wait"),
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        preConfirm: async () => {
          let data = false;
          try {
            data = await this.$http.post(prepareRoute, filters);
          } catch (e) {
            this.$swal.insertQueueStep(error);
            return;
          }

          fileName = data.data.data;
          this.$swal.insertQueueStep(download);
        },
      };
      const error = {
        icon: "error",
        title: "Error",
        text: this.$t("notifications.download.error"),
      };
      const download = {
        icon: "success",
        title: this.$t("notifications.download.downloading"),
        allowOutsideClick: () => !this.$swal.isLoading(),
        onOpen: async () => {
          this.$swal.showLoading();
          let response = false;
          try {
            response = await this.$http.get(downloadRoute + fileName, {
              responseType: "blob",
            });
          } catch (e) {
            // console.log('Cant download file', e)
            await this.$swal.queue([retry]);
            return;
          }

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();

          this.$swal.close();
        },
      };
      const retry = {
        icon: "error",
        title: "Error",
        text: this.$t("notifications.download.failed"),
        confirmButtonText: this.$t("notifications.download.retry"),
        allowOutsideClick: true,
        preConfirm: () => {
          this.$swal.insertQueueStep(download);
        },
      };

      this.$swal.queue([prepare]);
    },
    submit() {
      const team = {
        id: this.team.id,
        users: {
          _ids: [],
        },
      };

      this.membersList.forEach((m) => {
        team.users._ids.push(m.id);
      });

      this.$emit("submit", team);
    },
  },
};
</script>
